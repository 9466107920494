import React from 'react'
import Icon from './Icon'

const Icons = (props) => {
    return (
        <>
        <h2>Services</h2>
        <p className="mb-4">{props.description}</p>
        <div className="flex justify-center flex-wrap lg:flex-no-wrap lg:justify-between">    
            <Icon iconSrc="/img/icons/chemicals.svg" serviceName="Chemicals" />
            <Icon iconSrc="/img/icons/maintenance.svg" serviceName="Maintenance services" />
            <Icon iconSrc="/img/icons/pipework.svg" serviceName="Pipework repair" />
            <Icon iconSrc="/img/icons/hottub.svg" serviceName="Hot tub repairs" />
            <Icon iconSrc="/img/icons/coverservice.svg" serviceName="Cover system servicing" />
            <Icon iconSrc="/img/icons/newcovers.svg" serviceName="New covers" />
            <Icon iconSrc="/img/icons/construction.svg" serviceName="Construction" />    
        </div>
        </>
        );
}

export default Icons;