import React from 'react'
import Helmet from 'react-helmet'
const Insta = () => {
    return(
        <div id="insta-widget-container" className="w-full">
            <Helmet><script src="https://apps.elfsight.com/p/platform.js" defer></script></Helmet>
            <div className="elfsight-app-89595871-1e7e-4cd2-9e21-f3d26dc01f1f"></div>
        </div>
    );
}

export default Insta;