import React from 'react'
//import Chemicals from '../../../img/icons/chemicals.svg' 

const Icon = (props) => {
    return (
        <div className="text-center flex justify-start items-center flex-wrap flex-col w-6/12 sm:w-4/12 lg:w-2/12 px-2 mb-6 md:mb-8 lg:mb-0">
            <div className="rounded-full bg-lightpink w-20 h-20 flex justify-center items-center">
            <img src={props.iconSrc} />
            </div>
            <h3>{props.serviceName}</h3>    
        </div>
    );
}

export default Icon;