import React from 'react'
import { Link } from 'gatsby'
import classes from './Banner.module.css'

const Banner = (props) => {

  const bannerStyle = {
    backgroundImage: 'url(' + props.image.source_url + ')',
    backgroundPosition: 'center center',
  };

  return (
    <div
      className={`${classes.bannerBackground} w-full relative bg-cover bg-center flex justify-center items-end z-0`} style={bannerStyle}
    >
      <img
        className="hidden md:block waveImg waveImgFlipped z-30 absolute top-0 -mt-1"
        src="/img/wavewhite.svg"
        alt="Swimming pool equipment"
      />
      <div className="w-full h-tall md:h-short lg:h-tall darkBlueGradHalf absolute z-10"></div>
      <div className="w-10/12 lg:w-7/12 mb-6 z-20">
        <h2 className="text-shadow text-gray-100 h1 leading-none xl:leading-tight mb-4">
          {props.copy}
        </h2>
        <Link className="button button-lg" to="/contact">Enquire</Link>
      </div>
    </div>
  )
}

export default Banner
