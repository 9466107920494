import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Icons from '../components/blocks/Icons/Icons'
// import Slider from '../components/blocks/Slider'
import Seasonal from '../components/blocks/Seasonal'
import Banner from '../components/blocks/Banner'
import Insta from '../components/blocks/Insta'
import CallToAction from '../components/blocks/CallToAction'
import Seo from '../components/Seo'

const Homepage = () => {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(limit: 1, filter: { wordpress_id: { eq: 29 } }) {
        edges {
          node {
            status
            content
            wordpress_id
            featured_media {
              source_url
            }
            acf {
              call_to_action_content
              carousel_content_1
              carousel_content_2
              carousel_content_3
              carousel_title_1
              carousel_title_2
              carousel_title_3
              services_description
              spring_open_content
              spring_open_price
              spring_open_title
              winter_close_content
              winter_close_price
              winter_close_title
              home_banner_text
              home_banner_image {
                source_url
              }
              clean_image_1 {
                source_url
              }
              clean_image_2 {
                source_url
              }
              clean_image_3 {
                source_url
              }
              green_image_1 {
                source_url
              }
              green_image_2 {
                source_url
              }
              green_image_3 {
                source_url
              }
            }
          }
        }
      }
      allWordpressSiteMetadata {
        edges {
          node {
            description
            name
          }
        }
      }
    }
  `)

  const acfData = data.allWordpressPage.edges[0].node.acf;

  return(
  <Layout featuredImg={data.allWordpressPage.edges[0].node.featured_media.source_url} pageName={data.allWordpressSiteMetadata.edges[0].node.description}>
    <section className="pt-4 lg:w-6/12 mx-auto text-center">
      <div dangerouslySetInnerHTML={{__html: data.allWordpressPage.edges[0].node.content}}></div>
      <div className="divider bg-darkblue mb-4 mt-0"></div>
    </section>
    <section className="pb-4 w-10/12 mx-auto text-center">
      <Icons description={acfData.services_description}/>
    </section>
    {/* <section className="w-full">
      <Slider carouselContent={acfData} />
    </section> */}
    <section className="py-4 w-10/12 mx-auto">
      <Seasonal 
      springContent={acfData.spring_open_content}
      springPrice={acfData.spring_open_price}
      springTitle={acfData.spring_open_title}
      winterContent={acfData.winter_close_content}
      winterPrice={acfData.winter_close_price}
      winterTitle={acfData.winter_close_title}
      />
    </section>
    <section className="my-4 w-full overflow-hidden relative">
    {/* <Parallax y={['-800px', '800px']} className="absolute">
        <img src="/img/woman-relaxing-in-pool.jpg" />
      </Parallax> */}
      <Banner copy={acfData.home_banner_text} image={acfData.home_banner_image}/>
    </section>
    <section className="py-4 w-10/12 mx-auto">
      <Insta />
    </section> 
    <CallToAction ctaCopy={acfData.call_to_action_content} />
    <Seo title="Home" description={data.allWordpressPage.edges[0].node.content} image={data.allWordpressPage.edges[0].node.featured_media.source_url} article={true} />
  </Layout>
)
    }

export default Homepage
